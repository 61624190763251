<template>
    <v-autocomplete
      v-model="value"
      cache-items
      dense
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      hide-selected
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="MatId"
      item-text="MatNom"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      :readonly="readonly"
      return-object
      ref="MatFindCtrl"
      :clearable="!readonly"
      :multiple="multiple"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @update:search-input="UpdateSearchInputEvt"
      @blur="blurEvt">
      </v-autocomplete>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: ['value', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips', 'selectOnReadonly'],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      value() {
        if (this.value != null) {
          this.loadItems('');
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
          Action: 'GET_DATA_LIST_PAGINATED',
          CampoBusqueda: 'MatNom',
          ValorBuscar: valFind,
          NumRegsPag: 35,
          NumPag: 1,
          EmpId: this.empId,
          Searcher: true
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/mat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          result.data.EntsList.forEach(element => {
            var itemObject = {
              MatId: element.MatId, 
              MatNom: element.MatNom, 
              MatVarId1: element.MatVarId1,
              MatVarId2: element.MatVarId2,
              MatVar1: element.MatVar1,
              MatVar2: element.MatVar2
            }
            this.itemsList.push(itemObject);
          });

          if (valorActual != null) { 
            // if (valorActual.length == null) {
            if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.MatId != null) {
                  if (!this.itemsList.some(itm=>itm.MatId === valorActual.MatId)) {
                    this.itemsList.push(valorActual);
                  }
                }
            }
            else {
              valorActual.forEach(element => {  // es un array
                if (!this.itemsList.some(itm=>itm.MatId === element.MatId)) {
                  this.itemsList.push(element);
                }
              });
            }
          }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.MatFindCtrl.isMenuActive) {
            this.$refs.MatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.MatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.MatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      UpdateSearchInputEvt() {
        this.$emit('update:search-input', this.value)
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
        this.itemsList = [];
        this.loadItems('');
    }
}
</script>