<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nueva</span>
                    <span v-if="!isNew">Editar</span>
                    categoría de material
                </div>

                <v-form
                    ref="form"
                    v-model="isValid"
                    autocomplete="off">
                <v-tabs v-model="tab" background-color="transparent" show-arrows>
                    <v-tabs-slider color="blue"></v-tabs-slider>

                    <v-tab href="#tab-matcat">Categoría</v-tab>
                    <v-tab href="#tab-emp">Empresas</v-tab>
                    <v-tab href="#tab-mat">Materiales</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :value="'tab-matcat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <!-- FORMULARIO CATEGORIA -->
                            <v-row>
                                <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="matCat.MatCatCod"
                                        label="Código">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        required
                                        :rules="[v => !!v || 'El nombre es obligatorio']"
                                        v-model="matCat.MatCatNom"
                                        label="Nombre *">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        :value="matCat.MatCatParent != null ? matCat.MatCatParent.MatCatNom : ''"
                                        tabindex="-1"
                                        readonly
                                        label="Tipo">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <v-textarea
                                        class="mt-0 mb-n7"
                                        outlined
                                        auto-grow
                                        v-model="matCat.MatCatObs"
                                        label="Observaciones">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-emp'" eager>
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- EMPRESAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <EmpresasRelacionadas
                                        ref="relatedEmps"
                                        :empList="empDataList" 
                                        :empTotal="empDataTotal" 
                                        :loading="loading" 
                                        @update-data="updateEmpData">
                                    </EmpresasRelacionadas>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-mat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- MATERIALES -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                  <!-- Botón añadir material -->
                                  <v-btn
                                      :loading="loading"
                                      :disabled="editMat"
                                      @click="onBtnNuevoMat"
                                      color="primary"
                                      small
                                      class="mb-2">
                                      Añadir material
                                  </v-btn>
                                  
                                  <v-data-table
                                      :headers="matHeaders"
                                      :items="matDataRows"
                                      :options.sync="matOptions"
                                      :footer-props="{itemsPerPageOptions: [15]}"
                                      :server-items-length="matTotalDataRows"
                                      :loading="matLoading"
                                      style="overflow: scroll"
                                      mobile-breakpoint="0"
                                      class="elevation-1"
                                      dense>
                                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                                      <template v-slot:item.Opt="{ item }">
                                          <v-btn
                                              color="error"
                                              class="mr-2 my-1"
                                              small
                                              @click.stop="onBtnShowMatDeleteModal(item)">
                                              <v-icon>mdi-delete</v-icon>
                                          </v-btn>
                                      </template>
                                      <!-- NUEVO MATERIAL FORMULARIO -->
                                      <template v-if="editMat" slot="body.append">
                                          <tr class="new-row">
                                              <td>
                                                  <MatFind class="mb-n7" ref="matCtrl" v-model="newMat" label="Material" @change="validateMat"></MatFind>
                                              </td>
                                              <td style="min-width: 250px" align ="right">
                                                  <v-btn
                                                      :loading="matLoading"
                                                      :disabled="!isValidNewMat"
                                                      @click="onBtnSaveMat"
                                                      color="primary"
                                                      class="mt-2 mb-4 mr-2"
                                                      small>
                                                      Aceptar
                                                  </v-btn>
                                                  
                                                  <v-btn
                                                      @click="onBtnCancelMat"
                                                      class="mt-2 mb-4 mr-2"
                                                      small>
                                                      Cancelar
                                                  </v-btn>
                                              </td>
                                          </tr>
                                      </template>
                                  </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        @click="goToList">
                        Cancelar
                    </v-btn>

                    <!-- && canDelete" -->
                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Categoria  -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORIA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar material -->
        <v-dialog
            v-model="showMatDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR MATERIAL
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el material?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="matLoading"
                        color="primary"
                        @click="onBtnMatDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelMatDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
import MatFind from "../components/MatFind.vue";
export default ({
    components: {
        EmpresasRelacionadas, MatFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false,
            showDeleteDialog: false,
            showMatDeleteDialog: false,
            matCat: {
                MatCatId: 0,
                MatCatCod: '',
                MatCatNom: '',
                MatCatObs: '',
                MatCatParent: null,
                MatCatIdParent: null
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Tabla materiales
            matLoading: false,
            matTotalDataRows: 0,
            matDataRows: [],
            matOptions: {page: 1, itemsPerPage: 15},
            matHeaders: [
                { text: 'Materiales relacionados', width: 325, sortable: false, value: 'MatNom' },
                { text: '', width: 100, sortable: false, value: 'Opt', align: 'end' }
            ],
            matOptionsLoaded: false,
            // Edición materiales
            editMat: false, // Indica si se esta editando el nuevo material
            newMat: {},
            isValidNewMat: false,
            selectedMat: null, // Indica el material seleccionada para el borrado
            addedMats: [],
            deletedMats: []
        }
    },
    watch: {
      matOptions: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.matOptionsLoaded) {
            this.matOptionsLoaded = true;
            return;
          }
          this.loadRelatedMats();
        },
        deep: true
      },
      // matCat: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.matCat.MatCatId = this.$route.params.id;
        this.loadData();
        this.loadRelatedMats();
      } else {
        // Init MatCatParent
        if(this.$store.state.MatCatParentData != null) {
          this.matCat.MatCatParent = this.$store.state.MatCatParentData;
          this.matCat.MatCatIdParent = this.$store.state.MatCatParentData.MatCatId;
        }

        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/categorias-material');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_MATCAT',
            MatCatId: this.matCat.MatCatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/matcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.MatCat;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                item,
                // canDelete
              })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la categoría.');
                this.goToList();
            }
          });
        })
      },
      matCatHasMatFromApi(matId) {
        this.matLoading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'MATCAT_HAS_MAT',
            MatCatId: this.matCat.MatCatId,
            MatId: matId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/matcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const any = result.data;

            setTimeout(() => {
                this.matLoading = false;
                resolve({
                  any
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.matLoading = false;
                this.isValidNewMat = false;
            }
          });
        })
      },
      getRelatedMatListFromApi() {
        this.matLoading = true;

        var NumRegsPag = this.matOptions.itemsPerPage;
        var NumPag = this.matOptions.page;
        
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_CATEGORY_RELATED_MAT_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            MatCatId: this.matCat.MatCatId,
            EmpId: this.empId,
            AddedMatIds: this.addedMats,
            DeletedMatIds: this.deletedMats
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/mat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.matLoading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.matLoading = false;
                alert('Error al cargar los materiales.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const empIds = this.empDataList.map(m => m.EmpId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            MatCat: this.matCat,
            EmpIds: empIds,
            AddedMatIds: this.addedMats,
            DeletedMatIds: this.deletedMats
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/matcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la categoría.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            MatCatId: this.matCat.MatCatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/matcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la categoría.');
            }
          });
        })
      },
      //#endregion
      
      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Categoría no encontrada.');
              this.goToList();
              return;
            }

            this.matCat = data.item;
            // this.canDelete = data.canDelete;
            
            // Cargamos los datos de empresas
            this.empDataTotal = data.item.MatCatEmp.length;
            this.empDataList = [];
            data.item.MatCatEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
          });
      },
      loadRelatedMats() {
        this.getRelatedMatListFromApi()
        .then(data => {
          this.matDataRows = data.items;
          this.matTotalDataRows = data.total;
        });
      },
      loadDefaultEmps () {
        this.getDefaultEmpsFromApi()
          .then(data => {
            this.empDataTotal = data.items.length;
            this.empDataList = [];
            data.items.forEach(i => {
              this.empDataList.push({
                EmpId: i.EmpId,
                EmpNom: i.EmpNom
              });
            });
          });
      },
      //#endregion

      //#region Añadir materiales
      onBtnNuevoMat() {
        this.editMat = true;
        this.newMat = null;
        this.validateMat();
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.MatCatId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar la categoría.');
            }
          });
      },
      onBtnSaveMat() {
        if(this.newMat == null) { return; }
        const index = this.deletedMats.indexOf(this.newMat.MatId);
        if(index == -1) {
          this.addedMats.push(this.newMat.MatId);
        } else {
          this.deletedMats.splice(index, 1);
        }
        this.newMat = null,
        this.editMat = false;
        this.loadRelatedMats();
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de material
      onBtnCancelMat() {
        this.editMat = false;
      },
      //#endregion

      //#region Eliminar materiales
      removeMat(item) {
        const index = this.addedMats.indexOf(item.MatId);
        if(index == -1) {
          this.deletedMats.push(item.MatId);
        } else {
          this.addedMats.splice(index, 1);
        }
      },
      onBtnMatDelete() {
        this.removeMat(this.selectedMat);
        this.selectedMat = null;
        this.showMatDeleteDialog = false;
        this.loadRelatedMats();
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowMatDeleteModal(item) {
        this.selectedMat = item;
        this.showMatDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelMatDelete() {
        this.selectedMat = null;
        this.showMatDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
        // if (this.matCat == null) {
        //     this.isValid = false;
        //     return;
        // }
        // this.isValid = this.matCat.MatCatNom != null && this.matCat.MatCatNom != '';
      },
      validateMat() {
        if(this.newMat == null) {
          this.isValidNewMat = false;
          return;
        }
        // Si esta añadido no se puede volver a añadir
        if(this.addedMats.indexOf(this.newMat.MatId) != -1) {
          this.isValidNewMat = false;
          return;
        }
        // Si esta borrado se puede volver a añadir
        if(this.deletedMats.indexOf(this.newMat.MatId) != -1) {
          this.isValidNewMat = true;
          return;
        }
        // Comprobamos si ya pertenece a la categoría
        this.matCatHasMatFromApi(this.newMat.MatId).then(data => {
          this.isValidNewMat = !data.any;
        });
      }
      //#endregion
    } 
})
</script>
